<script context="module">
  let count = 0;
  export const getId = () => count++;
</script>

<script lang="ts">
  import { colors } from 'design/src/tokens/colors';

  interface $$Props extends Omit<Partial<HTMLInputElement>, 'value'> {
    value: any;
    group: any;
    color?: RadioColor;
    class?: string;
  }

  type RadioColor = 'red' | 'orange' | 'green' | 'blue' | 'cyan' | 'gray';

  export let id: string = `radio-button-${getId()}`;
  export let value: any;
  export let group: any;
  export let color: RadioColor = 'orange';
  let clazz = '';
  export { clazz as class };

  let selectedColor = 'bg-orange';
  let focusedColor = 'bg-orange-light';
  const selectedShade = 500;
  const focusedShade = 800;

  $: {
    switch (color) {
      case 'orange':
        selectedColor = colors.orange[selectedShade];
        focusedColor = colors.orange[focusedShade];
        break;
      case 'green':
        selectedColor = colors.green[selectedShade];
        focusedColor = colors.green[focusedShade];
        break;
      case 'red':
        selectedColor = colors.red[selectedShade];
        focusedColor = colors.red[focusedShade];
        break;
      case 'blue':
        selectedColor = colors.blue[selectedShade];
        focusedColor = colors.blue[focusedShade];
        break;
      case 'cyan':
        selectedColor = colors.cyan[selectedShade];
        focusedColor = colors.cyan[focusedShade];
        break;
      case 'gray':
        selectedColor = colors.gray[selectedShade];
        focusedColor = colors.gray[focusedShade];
        break;
    }
  }
</script>

<div class="utheory-radio {clazz}">
  <input
    type="radio"
    bind:group
    {value}
    {id}
    {...$$props}
    on:change
    on:abort
    on:blur
    on:click
    on:focus
    on:input
    on:invalid
    on:keydown
    on:keypress
    on:keyup
    on:mousedown
    on:mouseenter
    on:mouseleave
    on:mousemove
    on:mouseout
    on:mouseover
    on:mouseup
  />
  <label
    style="--focused-color: {focusedColor}; --selected-color: {selectedColor}; --transition-duration: 0.05s;"
    for={id}><slot /></label
  >
</div>

<style lang="postcss">
  .utheory-radio {
    --radio-diameter: 0.75em;
  }

  input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;

    + label {
      position: relative;
      cursor: pointer;
      @apply pl-6;

      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: var(--radio-diameter);
        height: var(--radio-diameter);
        /**
         * 0.55 is a value that seems to center this well... but of course it all hinges
         * on the line height of the font. Using 50% doesn't work because of issues with
         * multi-line text
         */
        top: calc(0.55em - (var(--radio-diameter) / 2));
        /* top: calc(50% - (var(--radio-diameter) / 2)); */
        left: calc((1em - var(--radio-diameter)) / 2);
        background-color: #ffffff00;
        border: 1px solid #6f686a;
        transition: background-color var(--transition-duration, 0.1s) ease-in;
      }
    }

    &:checked {
      + label::before {
        background: var(--selected-color, 'orange');
        /* The background is removed in Windows high-contrast mode, so we
         need to set it explicitly here. `WindowText` is a system color
         that should work with whatever high contrast mode the user
         has set. */
        @media screen and (-ms-high-contrast: active) {
          & {
            background: WindowText;
          }
        }
      }
    }

    &:focus {
      + label::before {
        /* The first box shadow makes the border thicker without
         moving the UI (like border-width would). */
        /* box-shadow: 0 0px 0px 1px #6f686a, 0 0px 8px red; */

        /* Since box shadows don't show up in high contrast mode,
         we're adding a transparent outline (which does show up). */
        @apply outline-2 outline-double;
        outline-color: var(--focused-color, 'blue');
        transition: outline var(--transition-duration, 0.1s) ease-in,
          outline-color var(--transition-duration, 0.1s) ease-in;
      }
    }
  }
</style>
